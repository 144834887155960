import React, { Fragment } from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import Image from "gatsby-image"
import withStyles from "@material-ui/core/styles/withStyles"
import Layout from "../components/Layout/layout"
import { Container, Grid, SvgIcon, Typography } from "@material-ui/core"
import Inquiry from "../components/Inquiry"
import { Octagon as OctagonIcon, Smile as SmileIcon } from "react-feather"

const Company = ({ classes, data }) => {
  const {
    mdx: {
      frontmatter: { category, title, date, slug, tagline },
      id,
      body,
    },
  } = data

  const intro = data.mdx.exports.intro
  const image01 = data.mdx.exports.images[0]
  const image02 = data.mdx.exports.images[1]
  const image03 = data.mdx.exports.images[2]

  const img01 = image01.src.childImageSharp.fluid
  const img02 = image02.src.childImageSharp.fluid
  const img03 = image03.src.childImageSharp.fluid

  const about = data.mdx.exports.about
  const whyUs = data.mdx.exports.whyUs
  const callToAction = data.mdx.exports.callToAction

  return (
    <Layout>
      <Container className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xxs={12} xs={12} sm={12} md={4}>
            <Typography className={classes.itemPageHeader} gutterBottom>
              {tagline}
            </Typography>
            {intro.body.map((i, index) => (
              <Fragment key={index}>
                <Typography
                  variant="body1"
                  className={classes.copy}
                  gutterBottom
                >
                  {i}
                </Typography>
              </Fragment>
            ))}
          </Grid>
          <Grid item xxs={12} xs={12} sm={12} md={8}>
            <Image fluid={img01} alt={image01.caption} />
          </Grid>
          <Grid item xxs={12} xs={12}>
            <div className={classes.spacer} />
          </Grid>
          <Grid item xxs={12} xs={12} md={4}>
            <Image fluid={img02} alt={image02.caption} />
          </Grid>
          <Grid item xxs={12} xs={12} md={4}>
            <Image fluid={img03} alt={image03.caption} />
          </Grid>
          <Grid item xxs={12} xs={12} md={4}>
            <Typography className={classes.itemPageHeader} gutterBottom>
              {about.headline}
            </Typography>
            <Typography variant="body1" className={classes.copy}>
              {about.body}
            </Typography>
          </Grid>
          <Grid item xxs={12} xs={12}>
            <div className={classes.spacer} />
          </Grid>
          <Grid item container xxs={12} xs={12} sm={6}>
            <Grid item xxs={12} xs={12} sm={2}>
              <SvgIcon className={classes.icon}>
                <OctagonIcon />
              </SvgIcon>
            </Grid>
            <Grid item xxs={12} xs={12} sm={10}>
              <Typography className={classes.tagline}>
                {whyUs.headline}
              </Typography>
              {whyUs.body.map((why, index) => (
                <Fragment key={index}>
                  <Typography gutterBottom>{why}</Typography>
                </Fragment>
              ))}
            </Grid>
          </Grid>
          <Grid item container xxs={12} xs={12} sm={6}>
            <Grid item xxs={12} xs={12} sm={2}>
              <SvgIcon className={classes.icon}>
                <SmileIcon />
              </SvgIcon>
            </Grid>
            <Grid item xxs={12} xs={12} sm={10}>
              <Typography className={classes.tagline}>
                {callToAction.headline}
              </Typography>
              {callToAction.body.map((call, index) => (
                <Fragment key={index}>
                  <Typography gutterBottom>{call}</Typography>
                </Fragment>
              ))}
            </Grid>
          </Grid>
          <Grid item xxs={12} xs={12}>
            <div className={classes.spacer} />
          </Grid>
        </Grid>

        <Inquiry />
      </Container>
    </Layout>
  )
}

const styles = theme => ({
  root: {
    minWidth: 225,
    flexGrow: 1,
    margin: theme.spacing(0, 0.5, 0.5, 0.5),
  },
  container: {
    marginTop: 25,
  },
  itemPageHeader: {
    fontSize: "48px",
    fontWeight: "light",
    letterSpacing: "3px",
    textTransform: "uppercase",
    lineHeight: "97%",
  },
  copy: {
    fontSize: "20px",
    fontWeight: "light",
    lineHeight: "130%",
  },
  spacer: {
    marginTop: theme.spacing(2),
  },
  tagline: {
    fontSize: "28px",
    letterSpacing: "2px",
    textTransform: "uppercase",
    marginBottom: theme.spacing(2),
  },
  icon: {
    fontSize: "72px",
    fontWeight: "light",
    color: "#d3d3d3",
    textAlign: "center",
  },
})

export const query = graphql`
  query GetCompanyInfo($slug: String) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        title
        category
        date(formatString: "MMMM Do, YYYY")
        slug
        tagline
        image01 {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        image02 {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        image03 {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      id
      body
      exports {
        intro {
          body
        }
        images {
          id
          src {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
          caption
        }
        about {
          headline
          body
        }
        whyUs {
          headline
          body
        }
        callToAction {
          headline
          body
        }
      }
    }
  }
`
export default withStyles(styles)(Company)
